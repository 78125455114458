import React from 'react'
import './styles/reset.css'
import './styles/App.scss'
import AppRouter from './Approuter'
import Header from './components/Header.component'
import GetDomain from './utils/getDomain'


function App() {
  const className = 'App ' + GetDomain()
  return (
    <div className={ className }>
      <Header />
      <AppRouter />
    </div>
  )
}

export default App
