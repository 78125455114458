import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Loading from './components/loading.component'

const Home = React.lazy( () => import( './pages/Home' ) )
const ProjectList = React.lazy( () => import( './pages/Project-list' ) )
const ProjectDetail = React.lazy( () => import( './pages/Project-detail' ) )
const Contact = React.lazy( () => import( './pages/Contact' ) )
const About = React.lazy( () => import( './pages/About' ) )

const AppRouter: React.FC<any> = () => {
  return (
    <React.Suspense fallback={ <Loading /> } >
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/work' element={<ProjectList />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/about' element={<About />} />
        <Route path='/work/:title' element={<ProjectDetail />} />
        <Route path='/*' element={<Home />} />
      </Routes>
    </React.Suspense>
  )
}

export default AppRouter
