import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const ScrollToTop: React.FC<any> = ({ children }) => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto"
    })
  }, [location.pathname])

  return children || null
}

export default ScrollToTop
