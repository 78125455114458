import React from 'react'

const Loading: React.FC = () => {
  return (
    <p>
      Loading...
    </p>
  )
}

export default Loading
