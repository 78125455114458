import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MenuButton from './MenuButton.component'

const Navigation: React.FC = () => {
  const [ toggleState, setToggleState ] = useState( false )
  const toggleMenu = ( e: React.MouseEvent<HTMLElement> ) => {
    setToggleState( current => !current )
  }
  const MenuButtonProps = {
    toggleState: toggleState,
    toggleMenu: toggleMenu
  }
  return ( 
    <>
      <ul 
        className= { toggleState ? 'nav show-nav' : 'nav hide-nav' }
        >
        <li className='nav-item'>
          <Link to='/work'>
            Work
          </Link>
        </li>
        <li className='nav-item'>
          <Link to='/about'>
            About
          </Link>
        </li>
        <li className='nav-item'>
          <Link to='/contact'>
            Contact
          </Link>
        </li>
      </ul>
      <MenuButton 
        { ...MenuButtonProps } 
        />
    </>
  )
}

export default Navigation
