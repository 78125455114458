import React from 'react'
import MenuButtonData from '../types/MenuButton.type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const MenuButton: React.FC <MenuButtonData> = ( { 
  toggleState, 
  toggleMenu
  } ) => {
  return (
    <button
      onClick={ toggleMenu }
      className='nav-button'
      >
      { !toggleState ? (
        <FontAwesomeIcon 
          icon={ solid( 'bars' ) } 
          />
      ) : (
        <FontAwesomeIcon 
          icon={ solid( 'xmark' ) } 
          />
      ) }
    </button>
  )
}

export default MenuButton
