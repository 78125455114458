import React from 'react'
import { Link } from 'react-router-dom'
import Navigation from './Navigation.component'

const Header: React.FC = () => {
  return (
    <header className='header'>
      <Link to='/'>
        <div 
          className='logo' >
        </div>
      </Link>
      <Navigation />
    </header>
  )
}

export default Header
